import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LoginPayload, useLogin} from '../requests/use-login';
import {FormCheckbox} from '../../ui/forms/toggle/checkbox';
import {LinkStyle} from '../../ui/buttons/external-link';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import React, {useContext, useState} from 'react';
import {
  SiteConfigContext,
  SiteConfigContextValue,
} from '../../core/settings/site-config-context';
import {useSettings} from '../../core/settings/use-settings';
import {ModalBody} from "@common/ui/modal-body";
import {Modal} from "@common/ui/modal";
import {RunningWithErrorsIcon} from "@common/icons/material/RunningWithErrors";
import {CancelIcon} from "@common/icons/material/Cancel";
import {
  getBootstrapData
} from "@common/core/bootstrap-data/use-backend-bootstrap-data";
import {useUser} from "@common/auth/ui/use-user";

interface Props {
  onTwoFactorChallenge: () => void;
}
interface BanResponse {
  message: string
  trial: boolean
  url: string
}
export function LoginPage({onTwoFactorChallenge}: Props) {
  const {settings} = getBootstrapData();
  const {nls_url: nlsUrl} = settings;
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [response, setResponse] = useState<BanResponse|null>(null);

  const isWorkspaceLogin = pathname.includes('workspace');
  const searchParamsEmail = searchParams.get('email') || undefined;

  const {branding, registration, site, social} = useSettings();
  const siteConfig = useContext(SiteConfigContext);

  const demoDefaults =
    site.demo && !searchParamsEmail ? getDemoFormDefaults(siteConfig) : {};
  const form = useForm<LoginPayload>({
    defaultValues: {remember: true, email: searchParamsEmail, ...demoDefaults},
  });
  const login = useLogin(form);

  const heading = isWorkspaceLogin ? (
    <Trans
      values={{siteName: branding?.site_name}}
      message="To join your team on :siteName, login to your account"
    />
  ) : (
    <Trans message="Sign in to your account" />
  );

  const message = !registration.disable && (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to="/register">
            {parts}
          </Link>
        ),
      }}
      message="Don't have an account? <a>Sign up.</a>"
    />
  );

  const isInvalid = !!Object.keys(form.formState.errors).length;

  const closeModal = () => {
    form.clearErrors();
    setOpenModal(false);
  }

  return (
    <AuthLayout heading={heading} message={message}>
      <StaticPageTitle>
        <Trans message="Login" />
      </StaticPageTitle>
      <Form
        form={form}
        id={'login-form'}
        onSubmit={payload => {
          login.mutate(payload, {
            onSuccess: response => {
              if (response.two_factor) {
                onTwoFactorChallenge();
              }
            },
            onError: response => {
              // @ts-ignore
              const resp = response.response.data;
              if (resp.url) {
                setOpenModal(true);
                setResponse(resp as BanResponse);
              }
            }
          });
        }}
      >
        <FormTextField
          className="mb-32"
          name="username"
          type="text"
          label={<Trans message="Login" />}
          disabled={!!searchParamsEmail}
          invalid={isInvalid}
          required
        />
        <FormTextField
          className="mb-12"
          name="password"
          type="password"
          label={<Trans message="Password" />}
          invalid={isInvalid}
          labelSuffix={
            <Link className={LinkStyle} to="/forgot-password" tabIndex={-1}>
              <Trans message="Forgot your password?" />
            </Link>
          }
          required
        />
        <FormCheckbox name="remember" className="mb-32 block">
          <Trans message="Stay signed in for a month" />
        </FormCheckbox>
        <Button
          className="block w-full"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          disabled={login.isPending}
        >
          <Trans message="Continue" />
        </Button>
      </Form>

      {(openModal && response) && (
        <Modal closeModal={closeModal} title={"Unlock access"}>
          <ModalBody isLoading={false}
                     title={response.trial
                       ? 'Thank you for using GotBackup, your trial period is over. You will now be redirected to upgrade your account.'
                       : 'Thank you for using GotBackup! It looks like your payment didn’t go through to renew your subscription. Please log in and complete the payment process.'}
                     icon={response.trial ? <RunningWithErrorsIcon color={'rgb(36, 101, 181)'} size={'icon-lg'}/> : <CancelIcon color={'rgb(36, 101, 181)'} size={'icon-lg'}/>}>
            <a target={"_blank"} rel={"noreferrer"}
               href={response.url}
               className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center font-semibold text-sm h-36 px-8 ">
              <Trans message={"Renew your access now by clicking here."} />
            </a>
          </ModalBody>
        </Modal>
      )}

    </AuthLayout>
  );
}

function getDemoFormDefaults(siteConfig: SiteConfigContextValue) {
  if (siteConfig.demo.loginPageDefaults === 'randomAccount') {
    // random number between 0 and 100, padded to 3 digits
    const number = Math.floor(Math.random() * 100) + 1;
    const paddedNumber = String(number).padStart(3, '0');
    return {
      email: `admin@demo${paddedNumber}.com`,
      password: 'admin',
    };
  } else {
    return {
      email: siteConfig.demo.email ?? 'admin@admin.com',
      password: siteConfig.demo.password ?? 'admin',
    };
  }
}
